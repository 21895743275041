@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/mixins/deprecate";
@import "../../../node_modules/bootstrap/scss/mixins/hover";
@import "../../../node_modules/bootstrap/scss/mixins/background-variant";

$primary: #f6cc37;
/*$secondary: #292b2c;*/
$secondary: #000000;

$dark: #38383b;

@import "../../../node_modules/bootstrap/scss/variables";

@each $color, $value in $colors {
    @include bg-variant(".bg-#{$color}", $value, true);
}
